<template>
  <div id="app">
    <transition name="slide-fade" mode="in-out">
      <router-view />
    </transition>
  </div>
</template>

<style>
/* Remove bottom scrollbar */
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* Prevent horizontal scrolling */
}

/* Slide and Fade Transition */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide-fade-enter-from {
  transform: translateX(100%);
  opacity: 0;
}

.slide-fade-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.slide-fade-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.slide-fade-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #69F0AE;
}
</style>
