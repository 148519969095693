<template>
  <div class="home bg-gradient-to-br from-purple-700 via-indigo-800 to-gray-900 text-white">
    <section class="hero flex flex-col items-center justify-center text-center py-16">
      <h1 class="text-5xl font-extrabold mb-6">Welcome to HelloZed</h1>
      <p class="text-xl opacity-80 max-w-2xl">
        Create stunning generative images with the power of AI. Transform your ideas into reality in seconds!
      </p>
      <router-link to="/feed"
        class="mt-8 px-6 py-3 bg-indigo-600 hover:bg-indigo-800 rounded-lg font-bold text-lg transition">
        Get Started Now
      </router-link>
    </section>

    <!-- Showcase Section -->
    <section class="showcase py-16 bg-gray-800">
      <div class="container mx-auto px-4">
        <h2 class="text-4xl font-bold text-center mb-8">Explore Our Creations</h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          <div class="image-card">
            <img src="https://music.hellozed.com/storage/uploads/gKbnGzhsf55Reb9BxCSgpGrtBjmqzpD0nSnI0E4k.jpg"
              alt="Generative Art 1" class="rounded-lg shadow-lg">
          </div>
          <div class="image-card">
            <img src="https://music.hellozed.com/storage/uploads/generated_1732098678_Wk9AwuQnEd.png"
              alt="Generative Art 2" class="rounded-lg shadow-lg">
          </div>
          <div class="image-card">
            <img src="https://music.hellozed.com/storage/uploads/M128wl7mvSDuerDkoDkLLj6qYLAFLoJvcmPgYJx0.jpg"
              alt="Generative Art 3" class="rounded-lg shadow-lg">
          </div>
        </div>
      </div>
    </section>
    <!-- Features Section -->
    <section class="features py-16">
      <div class="container mx-auto px-4 text-center">
        <h2 class="text-4xl font-bold mb-8">Why Choose HelloZed AI?</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div class="feature p-6 bg-gray-800 rounded-lg">
            <h3 class="text-2xl font-bold mb-4">Unmatched Creativity</h3>
            <p>Generate unique and breathtaking images tailored to your imagination.</p>
          </div>
          <div class="feature p-6 bg-gray-800 rounded-lg">
            <h3 class="text-2xl font-bold mb-4">User-Friendly Interface</h3>
            <p>Simple and intuitive design lets anyone create art effortlessly.</p>
          </div>
          <div class="feature p-6 bg-gray-800 rounded-lg">
            <h3 class="text-2xl font-bold mb-4">Lightning Fast</h3>
            <p>Get your creative outputs within seconds, powered by advanced AI technology.</p>
          </div>
        </div>
      </div>
    </section>
    <!-- CTA Section -->
    <section class="cta py-16 bg-gradient-to-br from-indigo-700 to-purple-900">
      <div class="container mx-auto px-4 text-center">
        <h2 class="text-3xl font-bold mb-6">Unleash Your Creativity Today</h2>
        <router-link to="/feed"
          class="px-8 py-3 bg-indigo-600 hover:bg-indigo-800 rounded-lg font-bold text-lg transition">
          Start Creating Now
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
}
</script>

<style scoped>
/* Add any additional custom styles */
.container {
  max-width: 1200px;
}
</style>
